import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { ActionStep, ActionStepTemplate, RemoteCopy } from 'types';

import ActionStepCard from './components/ActionStepCard';
import ActionStepForm from './components/ActionStepForm';
import ActionStepSummary from './components/ActionStepSummary';
import AddBorder from 'assets/border-add.svg';
import BonusTrackerCard from './components/BonusTrackerCard';
import CardBackground from 'components/_shared/CardBackground';
import LoadingCircle from 'components/_shared/LoadingCircle';
import MessageBubble from 'components/_shared/MessageBubble';
import Modal from 'components/_shared/Modal';
import Sparkle from 'assets/lottie/sparkle-effect.json';

import useRemoteCopy from 'hooks/useRemoteCopy';
import { incrementIntakeStep } from 'redux/actions/intake';

import { Button, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    stepsContainer: {
        position: 'relative',
        display: 'flex',
        justifySelf: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '16px',
        height: 'fit-content',
        margin: '0 24px 56px 24px',
        padding: '50px 0 0 0',
        width: '90%',
        maxWidth: '720px',
        '@media (max-width: 768px)': {
            width: '90%'
        }
    },
    addButton: {
        animation: '$pulse 2.5s infinite',
        background: 'linear-gradient(180deg, #CCD3FF 0%, #7771B4 100%)',
        backgroundColor: '#9A9EE7',
        border: 'none',
        borderRadius: '100%',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '40px',
        height: '60px',
        width: '60px'
    },
    addButtonContent: {
        color: '#7E72D4',
        font: 'Manrope',
        fontWeight: '800',
        letterSpacing: '0.15em',
        lineHeight: '40px',
        padding: '10px',
        size: '40px',
        textAlign: 'center'
    },
    fadeContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
        width: '100%'
    },
    needContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        maxWidth: '100%',
        minHeight: '50%',
        minWidth: '85%',
        padding: '0 40px',
        zIndex: '1'
    },
    addContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${AddBorder})`,
        backgroundColor: '#EAEFF3',
        borderRadius: '40px',
        minHeight: '300px',
        width: '100%',
        '&:active': {
            transform: 'scale(0.98)'
        }
    },
    btnContainer: {
        display: 'flex',
        width: '100%'
    },
    skipBtn: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '100px',
        fontSize: '28px',
        fontWeight: '700',
        margin: '10px',
        padding: '12px 72px',
        textTransform: 'none',
        '& i': {
            fontSize: '40px',
            marginLeft: '15px'
        }
    },
    noTooltip: {
        margin: '40px',
        width: '95%'
    },
    tooltipContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FEF3E7',
        borderRadius: '20px 20px 40px 40px',
        color: '#7A4406',
        margin: '10px 0',
        width: '95%'
    },
    bonusTooltip: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px 32px 24px 32px'
    },
    tooltipHeader: {
        fontSize: '16px',
        fontWeight: '800',
        lineHeight: '22px',
        '& i': {
            marginRight: '8px'
        }
    },
    tooltipBody: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '22px'
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(154, 158, 231, 0.4)'
        },
        '70%': {
            boxShadow: '0 0 0 10px rgba(154, 158, 231, 0)'
        },
        '100%': {
            boxShadow: '0 0 0 0 rgba(154, 158, 231, 0)'
        }
    }
}));

type ActionStepProps = {
    intakeGoals: any;
    maxBonusAmount: number;
    progressBarWidth: number;
    remoteCopy: null | RemoteCopy;
    setIntakeGoals: any;
    setIntakeNeeds?: any;
    setGuide: any;
    templateActions: ActionStepTemplate[] | null;
};

const ActionSteps: React.FC<ActionStepProps> = ({
    intakeGoals,
    maxBonusAmount,
    progressBarWidth,
    remoteCopy,
    setGuide,
    setIntakeGoals,
    templateActions
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const actions = useRemoteCopy(`action_step_suggestions`);
    const refetchCount = useRef(0);

    const [editAction, setEditAction] = useState<number[]>([]);
    const [isErrored, setIsErrored] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [temporaryAction, setTemporaryAction] = useState<
        ActionStep | ActionStepTemplate | null
    >(null);

    const [loopCount, setLoopCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);

    const cardStaticStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        width: '100%'
    };

    const cardSpring = useSpring({
        config: { tension: 80, friction: 15 },
        delay: temporaryAction ? 1000 : 0,
        from: {
            opacity: 1,
            y: 0
        },
        to: {
            opacity: 1,
            y: temporaryAction ? -137 : 0
        },
        onRest: () => {
            if (temporaryAction) {
                setIsVisible(true);
            }
        }
    });

    const animatedStyle = {
        ...cardStaticStyle,
        opacity: cardSpring.opacity,
        transform: cardSpring.y.to((y) => `translateY(${y || 0}px)`)
    };

    const calculateBonusAmount = () => {
        let totalBonusAmount = 0;

        if (intakeGoals) {
            intakeGoals.map(
                (goal: { default: boolean; amount: number; bonus: number }) => {
                    if (goal.default !== true) {
                        if (goal.amount) {
                            totalBonusAmount += Number(goal.amount);
                        }

                        if (goal.bonus) {
                            totalBonusAmount += Number(goal.bonus);
                        }
                    }

                    return null;
                }
            );
        }

        if (temporaryAction) {
            totalBonusAmount += Number(
                temporaryAction.bonus || temporaryAction.amount || 0
            );
        }

        if (maxBonusAmount - totalBonusAmount < 0) {
            return 0;
        } else {
            return maxBonusAmount - totalBonusAmount;
        }
    };

    const getAssignedBonusMessage = () => {
        const currentMonth = new Date().toLocaleString('default', {
            month: 'long'
        });
        const nextMonth = new Date(
            new Date().setMonth(new Date().getMonth() + 1)
        ).toLocaleString('default', { month: 'long' });

        return (
            `You've assigned all of ${currentMonth}'s monthly bonus pool. ` +
            `You'll be able to assign bonuses again at the start of ${nextMonth}.`
        );
    };

    const handleAddClick = () => {
        if (temporaryAction) {
            setIntakeGoals([...intakeGoals, temporaryAction]);
            setTemporaryAction(null);
        }

        setEditAction([]);
        setIsVisible(false);
        setShowForm(true);
    };

    const handleEditClick = (index: number) => {
        setShowForm(false);

        if (index !== intakeGoals.length && !editAction.includes(index)) {
            setEditAction([...editAction, index]);
        }

        if (temporaryAction && index === intakeGoals.length) {
            setEditAction([...editAction, index]);
            setIntakeGoals([...intakeGoals, temporaryAction]);
            setTemporaryAction(null);
        }
    };

    const handleContinueClick = () => {
        const container = document.getElementById('intake-container');

        if (container) {
            container.scrollTop = 0;
        }

        if (temporaryAction) {
            setIntakeGoals([...intakeGoals, temporaryAction]);
            setTemporaryAction(null);
        }

        dispatch(incrementIntakeStep(progressBarWidth));
        setGuide(false);
        setShowForm(false);
    };

    const handleLoopComplete = () => {
        if (loopCount < 2) {
            setLoopCount(loopCount + 1);
        } else {
            setIsVisible(false);
        }
    };

    const renderAddText = () =>
        intakeGoals.length === 1
            ? `ADD ACTION STEP`
            : `ADD ANOTHER ACTION STEP`;

    const renderSkipBtnStyle = () => {
        if (showForm) {
            return {
                display: 'flex',
                fontSize: '20px',
                fontWeight: '700',
                color: '#7378E8',
                padding: '5px 20px',
                borderRadius: '36px',
                textTransform: 'none',
                '& span': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                },
                '& i': {
                    fontSize: '20px'
                }
            };
        } else {
            return {
                background: '#7378E8',
                color: '#FFFFFF'
            };
        }
    };

    const renderTitle = () =>
        remoteCopy!.pages.add_action_step.bubble.replace(
            /_([^_]*)_/g,
            '<i>$1</i>'
        );

    const shouldShowTooltip = calculateBonusAmount() === 0;

    useEffect(() => {
        if (actions.isError && refetchCount.current < 5) {
            refetchCount.current++;
            actions.refetch();
        }

        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [actions]);

    if (isErrored) {
        return (
            <Modal open={isErrored}>
                <div style={{ padding: 20 }}>
                    <div className="font-lg">Something has gone wrong.</div>
                    <div>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </div>
                </div>
            </Modal>
        );
    }

    if (!actions.isLoaded) {
        return <LoadingCircle />;
    }

    return (
        <div className={classes.stepsContainer}>
            <CardBackground />
            <MessageBubble question={renderTitle()} />
            <div className={classes.needContainer}>
                <BonusTrackerCard
                    maxBonusAmount={maxBonusAmount}
                    remainingBonusAmount={calculateBonusAmount()}
                />
                {intakeGoals.length > 0 && !showEditForm ? (
                    <ActionStepSummary
                        actionType={intakeGoals}
                        actionSuggestions={actions.remoteCopy}
                        editAction={editAction}
                        from="intake"
                        handleEditClick={handleEditClick}
                        intakeGoals={intakeGoals}
                        remainingBonusAmount={calculateBonusAmount()}
                        setEditAction={setEditAction}
                        setGuide={setGuide}
                        setIntakeGoals={setIntakeGoals}
                        setShowForm={setShowForm}
                        showForm={showForm}
                        temporaryAction={temporaryAction}
                    />
                ) : null}
                {temporaryAction &&
                    !editAction.includes(intakeGoals.length) && (
                        <div
                            style={{
                                height: showForm ? '680px' : '126px',
                                width: '100%'
                            }}
                        >
                            <animated.div style={animatedStyle}>
                                <Fade
                                    in={temporaryAction !== null}
                                    timeout={800}
                                >
                                    <div className={classes.fadeContainer}>
                                        {isVisible && (
                                            <Lottie
                                                animationData={Sparkle}
                                                onLoopComplete={
                                                    handleLoopComplete
                                                }
                                                loop={3}
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    height: '56px',
                                                    right: '-8px',
                                                    top: '16px',
                                                    width: '56px'
                                                }}
                                            />
                                        )}
                                        <ActionStepCard
                                            action={temporaryAction as any}
                                            handleEditClick={handleEditClick}
                                            index={intakeGoals.length}
                                            remainingBonusAmount={calculateBonusAmount()}
                                            temporaryAction={temporaryAction}
                                        />
                                    </div>
                                </Fade>
                            </animated.div>
                        </div>
                    )}
                {!showForm && (
                    <Fade in={intakeGoals.length > 0} timeout={1000}>
                        <div
                            className={
                                shouldShowTooltip
                                    ? classes.tooltipContainer
                                    : classes.noTooltip
                            }
                        >
                            {shouldShowTooltip && (
                                <div className={classes.bonusTooltip}>
                                    <span className={classes.tooltipHeader}>
                                        <i className="fas fa-info-circle" />{' '}
                                        Heads Up!
                                    </span>
                                    <span className={classes.tooltipBody}>
                                        {getAssignedBonusMessage()}
                                    </span>
                                </div>
                            )}
                            <div className={classes.addContainer}>
                                <button
                                    className={classes.addButton}
                                    onClick={() => handleAddClick()}
                                >
                                    +
                                </button>
                                <span className={classes.addButtonContent}>
                                    {renderAddText()}
                                </span>
                            </div>
                        </div>
                    </Fade>
                )}
                {showForm && (
                    <Fade in={showForm} timeout={1000}>
                        <div style={{ width: '97.5%' }}>
                            <ActionStepForm
                                actionSuggestions={actions.remoteCopy}
                                editAction={editAction}
                                from="intake"
                                intakeGoals={intakeGoals}
                                remainingBonusAmount={calculateBonusAmount()}
                                setGuide={setGuide}
                                setIntakeGoals={setIntakeGoals}
                                setShowEdit={setShowEditForm}
                                setShowForm={setShowForm}
                                setTemporaryAction={setTemporaryAction}
                                showEdit={showEditForm}
                                templateActions={templateActions}
                                temporaryAction={temporaryAction}
                            />
                        </div>
                    </Fade>
                )}
            </div>
            <div
                className={classes.btnContainer}
                style={
                    showForm
                        ? { justifyContent: 'flex-end' }
                        : { justifyContent: 'center' }
                }
            >
                <Button
                    className={classes.skipBtn}
                    onClick={() => handleContinueClick()}
                    style={renderSkipBtnStyle() as any}
                >
                    {remoteCopy!.pages.add_need.submit_button}
                    <i className="fas fa-long-arrow-right" />
                </Button>
            </div>
        </div>
    );
};

export default ActionSteps;
