import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Route,
    useHistory,
    useLocation,
    useRouteMatch
} from 'react-router-dom';

import {
    Caseload,
    CatchUpItems,
    DebitCard,
    DebitCardOptions,
    DeploymentOrgs,
    DeploymentUsers,
    GoalActions,
    Homeless,
    Needs,
    NewNeed,
    PexHistory,
    ProfileItems,
    Referral,
    TouchpointAnswers,
    TouchpointQuestions,
    User
} from 'types';

import CaseManagement from './CaseManagement';
import CatchUp from 'containers/CatchUp';
import Celebration from './Celebration';
import Documents from './PrivateInfo/components/Documents';
import Funds from './Funds';
import Header from './Header';
import MemberHistory from './MemberHistory';
import Messaging from 'containers/Messaging';
import Nav from 'components/_shared/NavSelector';
import PopUp from './PopUp';
import PrivateInfo from './PrivateInfo';
import ProfileCompletion from './ProfileCompletion';
import PublicInfo from './PublicInfo';
import ReferMember from './ReferMember';
import RedeemView from '../profile/RedeemView';
import Toast from 'components/_shared/Toast';

import { fetchQOLMeasures } from 'api/quality_of_life_measures';
import {
    setImprovedTouchpointStatus,
    setReducedTouchpointStatus
} from 'redux/actions/touchpointAnswers';
import { updateTouchpointAnswerClassifiedStatus } from 'redux/actions/touchpointAnswers';

import SDOHSurvey from 'containers/StatusCapture';

import { withStyles } from '@mui/styles';

const styles = () => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: -1,
        position: 'relative'
    },
    profile: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    },
    lockScroll: {
        overflow: 'hidden'
    },
    wrapper: {
        position: 'relative',
        height: '100%'
    },
    viewContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 100,
        top: 0
    }
});

// TODO: Fix types
type Props = {
    acceptReferral: (referralID: number) => void;
    assignedBonusAmount: number;
    assignDebitCard: (
        memberID: number,
        type: 'givecard' | 'pex',
        last4: number,
        token: string
    ) => void;
    canConfirmMembership: boolean;
    caseloads: Caseload[];
    catchUpItems: CatchUpItems[];
    classes: {
        [key: string]: string;
    };
    createMemberCaseload: (memberID: number) => void;
    debitCardOptions: DebitCardOptions[];
    debitHistory: PexHistory[];
    debitInfo: DebitCard;
    deploymentOrgs: DeploymentOrgs[];
    deploymentUsers: DeploymentUsers[];
    getApiErrorResponse: { message: string } | {};
    getDebitCardHistory: (
        id: number,
        type: string,
        fullHistory?: boolean
    ) => void;
    getDebitCardInfo: (memberID: number) => void;
    getMemberPayments: (id: number) => void;
    getTouchpointAnswersById: (id: number) => void;
    historyFilters: {
        description: string;
        friendly_name: string;
    }[];
    homelessGoalActions: GoalActions[];
    homelessInfo: Homeless;
    homelessLifeStory: string;
    homelessLifeStorySuccess: boolean;
    homelessNeeds: Needs[];
    improvedStatus: {
        answer: {
            id: number;
        };
        oldAnswer: {
            classified_statuses: {
                classified_status_answer_matches: {
                    rank: number;
                }[];
            }[];
        };
        updatedAnswer: {
            id: number;
            classified_statuses: {
                classified_status_answer_matches: {
                    rank: number;
                }[];
            }[];
            touchpoint_question_id: number;
        };
    }[]; // fix this
    improvedStatusCount: number;
    intakeId: number;
    isEditingGoalAction: boolean;
    isFetchingHomelessGoalActions: boolean;
    isFetchingHomelessNeeds: boolean;
    isFetchingMemberPayments: boolean;
    isFetchingDebitHistory: boolean;
    isFetchingDebitInfo: boolean;
    isFetchingTouchpointAnswersById: boolean;
    isReferringMember: boolean;
    isSubmittingHomelessLifeStory: boolean;
    isSubmittingTouchpointAnswers: boolean;
    maxBonusAmount: number;
    memberPayments: any; // add this
    memberReferrals: Referral[];
    newHomelessGoalActions: any[]; // add this
    newHomelessNeeds: NewNeed[];
    previousAnswers: any[]; // add this
    profileItems: ProfileItems;
    redemptionList: any[]; // add this
    referMember: () => Promise<void>;
    referMemberError: boolean | null;
    rejectReferral: (referralID: number) => void;
    remainingBonusAmount: number;
    removeMemberFromMyMembers: (caseloadID: number | null) => void;
    resetIntake: () => void;
    saveMemberToMyMembers: (
        caseloadID: number | null,
        memberID: number
    ) => void;
    supplementalQuestions: TouchpointQuestions[];
    supplementalQuestionAnswers: [
        { answer: string; created_at: string; touchpoint_question_id: number }
    ]; // check this
    touchpointAnswers: TouchpointAnswers[];
    touchpointAnswersById: number[];
    touchpointQuestionQOLs: number[];
    touchpointQuestions: TouchpointQuestions[];
    updatedAnswerIds: number[];
    updatedQuestionIds: number[];
    updateGoalActionStatus: (id: number, status: string) => void;
    updateHomelessInfoField: (field: string, value: string) => void;
    updateHomelessLifeStory: (id: number, lifeStory: string) => void;
    updateProfileCompletedItems: (item: string) => void;
    user: User;
};

const Profile: React.FC<Props> = ({
    acceptReferral,
    assignedBonusAmount,
    assignDebitCard,
    canConfirmMembership,
    caseloads,
    catchUpItems,
    classes,
    createMemberCaseload,
    debitCardOptions,
    debitHistory,
    debitInfo,
    deploymentOrgs,
    deploymentUsers,
    getApiErrorResponse,
    getDebitCardInfo,
    getDebitCardHistory,
    getMemberPayments,
    getTouchpointAnswersById,
    historyFilters,
    homelessGoalActions,
    homelessInfo,
    homelessLifeStory,
    homelessLifeStorySuccess,
    homelessNeeds,
    improvedStatus,
    improvedStatusCount,
    intakeId,
    isEditingGoalAction,
    isFetchingHomelessGoalActions,
    isFetchingHomelessNeeds,
    isFetchingMemberPayments,
    isFetchingDebitHistory,
    isFetchingDebitInfo,
    isFetchingTouchpointAnswersById,
    isReferringMember,
    isSubmittingHomelessLifeStory,
    isSubmittingTouchpointAnswers,
    maxBonusAmount,
    memberPayments,
    memberReferrals,
    newHomelessGoalActions,
    newHomelessNeeds,
    previousAnswers,
    profileItems,
    redemptionList,
    referMember,
    referMemberError,
    rejectReferral,
    remainingBonusAmount,
    removeMemberFromMyMembers,
    resetIntake,
    saveMemberToMyMembers,
    supplementalQuestions,
    supplementalQuestionAnswers,
    touchpointAnswers,
    touchpointAnswersById,
    touchpointQuestionQOLs,
    touchpointQuestions,
    updatedAnswerIds,
    updatedQuestionIds,
    updateGoalActionStatus,
    updateHomelessInfoField,
    updateHomelessLifeStory,
    updateProfileCompletedItems,
    user
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const homepage =
    //     history.location.pathname === `/holders/${homelessInfo.id}`;
    const location = useLocation();
    const match = useRouteMatch();

    const { beacon_id, id, organization, profile_stage } = homelessInfo;

    const activeCatchUpItems = catchUpItems.filter(
        (item) => item.status === 'active'
    );
    const allCatchUpItems = catchUpItems.filter(
        (item) => item.status !== 'completed'
    );
    const exited = profile_stage === 'EXITED';
    const pending = profile_stage === 'PENDING';
    const unsuccessful = profile_stage === 'UNSUCCESSFUL_PENDING';
    const memoizedTouchpointAnswers = useMemo(
        () => touchpointAnswers,
        [touchpointAnswers]
    );
    const navItems = [
        'Case Management',
        !exited && !unsuccessful ? 'Manage Funds' : null,
        !exited && !unsuccessful ? 'Engaging Supporters' : null,
        'Activity History',
        'Account'
    ];

    const [active, setActive] = useState<string>('Case Management');
    const [addStatus, setAddStatus] = useState<boolean>(false);
    const [catchUpItemIndex, setCatchUpItemIndex] = useState<number>(0);
    const [celebrate, setCelebrate] = useState<boolean>(false);
    const [completeProfilePopup, setCompleteProfilePopup] =
        useState<boolean>(false);
    const [confirmMemberPopUp, setConfirmMemberPopUp] = useState(false);
    const [isReducedStatus, setIsReducedStatus] = useState<boolean>(false);
    const [polling, setPolling] = useState<boolean>(false);
    const [reenroll, setReenroll] = useState<boolean>(false);
    const [quickCatchUp, setQuickCatchUp] = useState<boolean>(false);
    const [referMemberPopUp, setReferMemberPopUp] = useState<boolean>(false);
    const [showCatchUp, setShowCatchUp] = useState<boolean>(
        activeCatchUpItems.length > 0
    );
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [unenroll, setUnenroll] = useState<boolean>(false);
    const [updatedQOLAnswers, setUpdatedQOLAnswers] = useState<
        TouchpointAnswers[]
    >([]);

    const activeTabValues = useMemo(() => {
        const { from, status }: { from: string; status: boolean } =
            history.location.state || ({} as any);

        let activeTab = 'Case Management';
        let isAddingStatus = false;
        let isUnenrolling = false;

        if (from === 'case' || from === 'updateStatusComplete') {
            activeTab = 'Case Management';

            if (status) {
                isAddingStatus = true;
            }
        }

        if (
            from === 'account' ||
            from === 'exitRedeem' ||
            from === 'exitedQol' ||
            from === 'redeem'
        ) {
            if (from === 'exitedQol') {
                isUnenrolling = true;
            }

            if (
                from === 'exitRedeem' &&
                !location.pathname.includes('redeem')
            ) {
                isUnenrolling = true;
            }

            activeTab = 'Account';
        }

        if (from === 'public') {
            activeTab = 'Engaging Supporters';
        }

        if (from === 'redeem') {
            activeTab = 'Manage Funds';
        }

        return { activeTab, isAddingStatus, isUnenrolling };
    }, [history.location.state, location.pathname]);

    const documentsURL = `${match.url}/documents/:name?`;
    const redeemURL = `${match.url}/redeem`;

    const renderCatchUp = false;
    // const renderCatchUp =
    //     active === 'Case Management' &&
    //     !exited &&
    //     !pending &&
    //     !unsuccessful &&
    //     homepage &&
    //     showCatchUp;
    const renderCelebration =
        !unenroll &&
        !addStatus &&
        celebrate &&
        improvedStatusCount &&
        improvedStatus.length > 0;

    const fetchClassifiedStatuses = useCallback(
        async (
            id: number,
            questionId: number,
            onAnswerFound: (answer: TouchpointAnswers) => void
        ) => {
            try {
                const res = await fetchQOLMeasures(questionId, id, true);
                const data = await res.json();
                const answer = data.touchpoint_answers[0];

                if (
                    answer.classified_statuses[0]
                        .classified_status_answer_matches.length === 0
                ) {
                    setPolling(true);
                } else {
                    setPolling(false);
                    setUpdatedQOLAnswers((prev) => {
                        const updatedAnswers = prev.map((prevAnswer) => {
                            if (prevAnswer.id === answer.id) {
                                return answer;
                            }
                            return prevAnswer;
                        });

                        return updatedAnswers;
                    });
                    onAnswerFound(answer);
                }
            } catch (error) {
                console.error('Error fetching classified statuses', error);
            }
        },
        []
    );

    const fetchUpdatedQOLs = useCallback(
        async (qolId: number) => {
            try {
                const res = await fetchQOLMeasures(qolId, id, true);
                const data = await res.json();
                const newAnswers = data.touchpoint_answers;

                if (newAnswers.length > 0) {
                    const newAnswer = newAnswers[0];

                    dispatch(updateTouchpointAnswerClassifiedStatus(newAnswer));

                    setUpdatedQOLAnswers((prev) => {
                        const updatedAnswers = prev.map((prevAnswer) => {
                            const matchingAnswer = newAnswers.find(
                                (newAnswer: { id: number }) =>
                                    newAnswer.id === prevAnswer.id
                            );
                            return matchingAnswer || prevAnswer;
                        });

                        const uniqueAnswers = [
                            ...updatedAnswers,
                            ...newAnswers.filter(
                                (newAnswer: { id: number }) =>
                                    !updatedAnswers.some(
                                        (prevAnswer) =>
                                            prevAnswer.id === newAnswer.id
                                    )
                            )
                        ];

                        return uniqueAnswers;
                    });
                } else {
                    return [];
                }
            } catch (error) {
                console.error('Error fetching updated QOLs', error);
            }
        },
        [dispatch, id]
    );

    const checkForStatusImprovements = useCallback(
        (oldAnswer: TouchpointAnswers, answer: TouchpointAnswers) => {
            if (!answer || !oldAnswer || polling) {
                return;
            }

            const getRank = (answer: TouchpointAnswers) => {
                if (
                    !answer?.classified_statuses ||
                    !Array.isArray(answer.classified_statuses) ||
                    answer.classified_statuses.length === 0
                ) {
                    return 0;
                }

                const firstStatus = answer.classified_statuses[0];

                if (
                    !firstStatus?.classified_status_answer_matches ||
                    firstStatus.classified_status_answer_matches.length === 0
                ) {
                    return 0;
                }

                return (
                    firstStatus.classified_status_answer_matches[0]?.rank || 0
                );
            };

            const pollForUpdates = async (qolId: number) => {
                let counter = 0;
                let isPolling = true;

                const interval = setInterval(async () => {
                    if (!isPolling) {
                        clearInterval(interval);
                        return;
                    }

                    counter++;

                    await fetchClassifiedStatuses(
                        id,
                        qolId,
                        (answer: TouchpointAnswers) => {
                            clearInterval(interval);

                            const oldAnswer = previousAnswers.find(
                                (prevAnswer: {
                                    touchpoint_question_id: number;
                                }) =>
                                    prevAnswer.touchpoint_question_id ===
                                    answer.touchpoint_question_id
                            );

                            // console.log('old answer', oldAnswer);
                            // console.log('new answer', answer);
                            checkForStatusImprovements(oldAnswer, answer);
                            isPolling = false;
                        }
                    );

                    if (counter > 24) {
                        clearInterval(interval);
                        isPolling = false;
                        return;
                    }
                }, 10000);

                return () => {
                    isPolling = false;
                    setPolling(false);
                    clearInterval(interval);
                };
            };

            const oldStatus = getRank(oldAnswer);
            let newStatus = getRank(answer);

            if (!newStatus) {
                const measure_id = touchpointQuestions.find((question: any) => {
                    return question.id === answer.touchpoint_question_id;
                })?.quality_of_life_measure_id;

                if (measure_id) {
                    setPolling(true);
                    pollForUpdates(measure_id);
                }
                return;
            }

            if (newStatus && newStatus > oldStatus) {
                const existingIndex = improvedStatus.findIndex(
                    (status) => status.answer.id === answer.id
                );

                if (existingIndex === -1) {
                    dispatch(
                        setImprovedTouchpointStatus({
                            oldAnswer,
                            answer
                        })
                    );
                }
            } else {
                setIsReducedStatus(true);

                dispatch(setReducedTouchpointStatus(answer));
            }
        },
        [
            dispatch,
            fetchClassifiedStatuses,
            id,
            improvedStatus,
            polling,
            previousAnswers,
            touchpointQuestions
        ]
    );

    const renderActive = () => {
        switch (active) {
            case 'Account':
                return <PrivateInfo {...privateInfoProps} />;
            case 'Case Management':
                return (
                    <CaseManagement {...caseManagementProps} {...statusProps} />
                );
            case 'Activity History':
                return <MemberHistory {...historyProps} />;
            case 'Manage Funds':
                return <Funds {...fundsProps} />;
            case 'Engaging Supporters':
                return <PublicInfo {...publicInfoProps} />;
            default:
                return (
                    <CaseManagement {...caseManagementProps} {...statusProps} />
                );
        }
    };

    const resetIntakeCallback = useCallback(() => {
        if (Number(id) === Number(intakeId)) {
            resetIntake();
        }
    }, [id, intakeId, resetIntake]);

    const caseManagementProps = {
        acceptReferral,
        assignedBonusAmount,
        caseloads,
        goalActions: homelessGoalActions,
        maxBonusAmount,
        memberReferrals,
        rejectReferral,
        remainingBonusAmount,
        removeMemberFromMyMembers,
        saveMemberToMyMembers,
        setReferMemberPopUp,
        setShowToast,
        setToastMessage,
        updateProfileCompletedItems
    };

    const catchUpProps = {
        catchUpItemIndex,
        debitInfo,
        getApiErrorResponse,
        items: !quickCatchUp ? activeCatchUpItems : allCatchUpItems,
        quickCatchUp,
        setAddStatus,
        setCatchUpItemIndex,
        setQuickCatchUp,
        setShowCatchUp
    };

    // const celebrationProps = {
    //     holder: homelessInfo,
    //     previousAnswer: improvedStatus[0].oldAnswer,
    //     updatedAnswer: improvedStatus[0].answer,
    //     setCelebrate,
    //     touchpointQuestions
    // };

    const fundsProps = {
        assignDebitCard,
        debitCardOptions,
        debitHistory,
        debitInfo,
        getDebitCardHistory,
        getMemberPayments,
        giveAutoTransfer: organization?.card_auto_transfer_balance || false,
        holder: homelessInfo,
        needs: homelessNeeds,
        isFetchingMemberPayments,
        memberPayments,
        pexAutoTransfer: organization?.pex_auto_transfer_balance || false,
        isFetchingDebitHistory,
        isFetchingDebitInfo,
        redemptionList
    };

    const headerProps = {
        caseloads,
        catchUpItems,
        confirmMemberPopUp,
        confirmUser: canConfirmMembership,
        createMemberCaseload,
        deploymentOrgs,
        exited,
        holder: homelessInfo,
        pending,
        profileItems,
        removeMemberFromMyMembers,
        saveMemberToMyMembers,
        setConfirmMemberPopUp,
        setCompleteProfilePopup,
        setQuickCatchUp,
        setReenroll,
        setReferMemberPopUp,
        setShowCatchUp,
        unsuccessful,
        userID: user.id
    };

    const historyProps = {
        historyFilters,
        holder: homelessInfo
    };

    const navProps = {
        active,
        getDebitCardInfo,
        holderID: id,
        navItems,
        setActive
    };

    const profileCompletionProps = {
        handleModalClick: setCompleteProfilePopup,
        holder: homelessInfo,
        maxBonusAmount,
        profileItems,
        remainingBonusAmount,
        supplementalQuestions,
        touchpointAnswers,
        touchpointQuestions,
        updateHomelessInfoField,
        updateProfileCompletedItems,
        user
    };

    const privateInfoProps = {
        canConfirmMembership,
        exited,
        holder: homelessInfo,
        setConfirm: setConfirmMemberPopUp,
        setReenroll,
        setUnenroll,
        unsuccessful,
        updateProfileCompletedItems,
        user
    };

    const publicInfoProps = {
        holder: homelessInfo,
        homelessLifeStorySuccess,
        isSubmittingHomelessLifeStory,
        lifeStory: homelessLifeStory,
        needs: homelessNeeds,
        newHomelessNeeds,
        supplementalQuestions,
        supplementalQuestionAnswers,
        touchpointAnswers,
        touchpointQuestions,
        touchpointQuestionQOLs,
        updateHomelessLifeStory,
        updateProfileCompletedItems,
        user
    };

    const referMemberProps = {
        deploymentOrgs,
        deploymentUsers,
        handleModalClick: setReferMemberPopUp,
        holder: homelessInfo,
        isReferringMember,
        referMember,
        referMemberError,
        setReferMemberPopUp,
        user
    };

    const statusProps = {
        addStatus,
        setAddStatus,
        exited,
        fetchTouchpointAnswersById: getTouchpointAnswersById,
        holder: homelessInfo,
        newHomelessGoalActions,
        isEditingGoalAction,
        isFetchingHomelessGoalActions,
        isFetchingHomelessNeeds,
        isFetchingTouchpointAnswersById,
        isSubmittingTouchpointAnswers,
        touchpointAnswers,
        touchpointAnswersById,
        touchpointQuestions,
        touchpointQuestionQOLs,
        unsuccessful,
        updateGoalActionStatus,
        user
    };

    useEffect(() => {
        const { activeTab, isAddingStatus, isUnenrolling } = activeTabValues;

        setActive(activeTab);
        setAddStatus(isAddingStatus);
        setUnenroll(isUnenrolling);
    }, [activeTabValues]);

    useEffect(() => {
        if (updatedQuestionIds.length > 0) {
            updatedQuestionIds.forEach((id: number) => {
                fetchUpdatedQOLs(id);
            });

            setIsReducedStatus(false);
        }
    }, [fetchUpdatedQOLs, updatedQuestionIds]);

    useEffect(() => {
        if (updatedQOLAnswers.length > 0 && updatedAnswerIds.length > 0) {
            let oldAnswer: TouchpointAnswers | null = null;
            const answers = memoizedTouchpointAnswers.find(
                (answer: TouchpointAnswers) =>
                    updatedQuestionIds[updatedQuestionIds.length - 1] ===
                    answer?.quality_of_life_measure_id
            );

            if (answers && !answers.answer) {
                answers.answers && answers.answer
                    ? (oldAnswer = answers)
                    : (oldAnswer = answers.answers[1] as TouchpointAnswers);
            }

            const lastUpdatedAnswerId =
                updatedAnswerIds[updatedAnswerIds.length - 1];
            const answer = updatedQOLAnswers.find(
                (answer) => answer.id === lastUpdatedAnswerId
            );

            const improvedStatusAnswer = improvedStatus.find(
                (status) => status.answer.id === answer?.id
            );

            if (
                oldAnswer &&
                answer &&
                !improvedStatusAnswer &&
                !isReducedStatus
            ) {
                checkForStatusImprovements(oldAnswer, answer);
            }
        }
    }, [
        checkForStatusImprovements,
        improvedStatus,
        isReducedStatus,
        memoizedTouchpointAnswers,
        updatedAnswerIds,
        updatedQOLAnswers,
        updatedQuestionIds
    ]);

    useEffect(() => {
        if (improvedStatusCount > 0) {
            setCelebrate(true);
        }
    }, [improvedStatusCount]);

    return (
        <>
            <Toast
                message={toastMessage}
                open={showToast}
                severity="success"
                viewClick={() => {
                    setActive('Manage Funds');
                    setShowToast(false);
                }}
            />
            <Route path={`${match.url}/qol`} component={SDOHSurvey} />
            <div
                className={classes.root}
                style={{
                    display:
                        location.pathname === `${match.url}/qol`
                            ? 'none'
                            : 'block'
                }}
            >
                <Route path={documentsURL} component={Documents} />
                <Route
                    path={redeemURL}
                    render={() => (
                        <RedeemView beaconId={beacon_id} homelessId={id} />
                    )}
                />
                <div ref={resetIntakeCallback} className={classes.profile}>
                    <div className={classes.wrapper}>
                        <Header {...headerProps} />
                        <Nav {...navProps} />
                        {renderActive()}
                        <Messaging holder={homelessInfo} />
                    </div>
                </div>
            </div>
            {completeProfilePopup && (
                <ProfileCompletion {...profileCompletionProps} />
            )}
            {referMemberPopUp && <ReferMember {...referMemberProps} />}
            {reenroll && (
                <PopUp
                    content="exited"
                    handleModalClick={setReenroll}
                    holder={homelessInfo}
                />
            )}
            {(renderCatchUp || quickCatchUp) && catchUpItems.length > 0 && (
                <CatchUp {...catchUpProps} />
            )}
            {unenroll && (
                <PopUp
                    content="unenroll"
                    handleModalClick={setUnenroll}
                    holder={homelessInfo}
                    setUnenroll={setUnenroll}
                />
            )}
            {renderCelebration && (
                <Celebration
                    holder={homelessInfo}
                    previousAnswer={improvedStatus[0].oldAnswer}
                    updatedAnswer={improvedStatus[0].answer as any}
                    setCelebrate={setCelebrate}
                    touchpointQuestions={touchpointQuestions}
                />
            )}
            {showCatchUp && <></>}
        </>
    );
};

export default withStyles(styles as any)(Profile);
